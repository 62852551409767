
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SharedModule } from '../../shared/shared.module'
import { Router } from '@angular/router';
import { AuthService, UtilsService } from '@app/core/services';
import { Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SharedModule, MatIconModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Output() clickEvent = new EventEmitter();
  isLogged!: boolean;
  isMobileDevice = false;
  currentUserLogged: any;
  private destroy$ = new Subject<void>();
  idUser!: string;

  constructor(private router: Router,
              public authService: AuthService,
              private utilsService: UtilsService,
              private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.WebLandscape]).subscribe(result => {
      this.isMobileDevice = result.breakpoints[Breakpoints.XSmall] ? true : false;
    })
  }

  ngOnInit(): void {
    this.authService.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.idUser = user.uid;
        this.getUser(user.email ?? '');
        this.isLogged = true;
      } else {
        this.isLogged = false;
      }
    })
  }
  
  getUser(id: string): void {
    if (id) {
      this.authService.getUserByEmail(id).then((result) => {
        if(result) this.currentUserLogged = result;
      });
    }
  }

  sendClickEvent(route:any){
    // route && this.router.navigateByUrl(route);
    this.clickEvent.emit(true);
  }
  convertPrice(e:any){
    return 0;
  }

  logout(): void {
    this.authService.logOut('/');
    this.clickEvent.emit(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}