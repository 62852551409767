<div class="ic-notifications mt-4 p-2">
  @if (isLogged && isMobileDevice) {
    <div class="flex items-center mb-3 relative">
      <mat-icon class="text-caranty-primary !w-16 !h-16 !text-[2.75rem] !flex !items-center">account_circle</mat-icon>
      <div class="w-full ml-2">
        <div class="ic-texts-container flex relative">
          <p class="text-base !m-0">
            Hola&nbsp;
            <strong>{{ currentUserLogged?.name | titlecase }}</strong>
          </p>
        </div>
      </div>
      <mat-icon class='font-bold absolute right-2' (click)="sendClickEvent(null)">close</mat-icon>
    </div>
  }

  @if (isLogged && isMobileDevice) {
    <!-- <hr class="ic-line m-0">
    <div class="text-black">
      <p class="text-base ic-opt mb-0 p-3">
        {{'HEADER.PROFILE.myBalance' | translate}}: $ {{ convertPrice(currentUserLogged?.balance) || 0 }}
      </p>
    </div> -->
    <hr class="ic-line m-0">
    <a [routerLink]="['/profile']" (click)="sendClickEvent('profile')" class="!text-black">
      <p class="text-base !mb-0 p-3">
        {{ 'HEADER.PROFILE.profile' | translate }}
      </p>
    </a>
    <hr class="ic-line m-0">
    <a [routerLink]="['/publications']" (click)="sendClickEvent('publications')" class="!text-black">
      <p class="text-base !mb-0 p-3">
        {{ 'HEADER.PROFILE.my-cars' | translate }}
      </p>
    </a>
    <hr class="ic-line m-0">
    <a [routerLink]="['/messages']" class="!text-black" (click)="sendClickEvent('messages')">
      <p class="!mb-0 text-base p-3">{{ 'HEADER.PROFILE.messages' | translate }}</p>
    </a>
    <hr class="ic-line m-0">
    <a [routerLink]="['/refunds']" class="!text-black" (click)="sendClickEvent('refund')">
      <p class="!mb-0 text-base p-3">{{ 'HEADER.PROFILE.refund' | translate }}</p>
    </a>
  }

  <hr class="ic-line m-0">
  <a [routerLink]="['/vende-tu-auto']" (click)="sendClickEvent('vende-tu-auto')" class="ic-pointer !text-black">
    <p class="text-base !mb-0 p-3">
      {{ 'HEADER.PROFILE.sell' | translate }}
    </p>
  </a>

  <hr class="ic-line m-0">
  <a [routerLink]="['/autos-seminuevos']" (click)="sendClickEvent('autos-seminuevos')" class="ic-pointer !text-black">
    <p class="text-base !mb-0 p-3">
      {{ 'HEADER.PROFILE.purchase' | translate }}
    </p>
  </a>

  <hr class="ic-line m-0">
  <a [routerLink]="['/sobre-caranty']" (click)="sendClickEvent('sobre-caranty')" class="ic-pointer !text-black">
    <p class="text-base !mb-0 p-3">
      {{ 'HEADER.PROFILE.whoCaranty' | translate }}
    </p>
  </a>

  <hr class="ic-line m-0">
  <a [routerLink]="['/preguntas-frecuentes']" (click)="sendClickEvent('preguntas-frecuentes')" class="ic-pointer !text-black">
    <p class="text-base !mb-0 p-3">
      {{ 'HEADER.PROFILE.faqs' | translate }}
    </p>
  </a>

  @if (isLogged) {
    <hr class="ic-line m-0">
    <a [routerLink]="['/autos-seminuevos']" [queryParams]="{favorites: true}"
    (click)="sendClickEvent('autos-seminuevos?favorites=true')" class="ic-pointer !text-black">
      <p class="text-base !mb-0 p-3">
        {{ 'HEADER.PROFILE.favorites' | translate }}
      </p>
    </a>
  }

  <hr class="ic-line m-0">
  <a [routerLink]="['/prensa']" (click)="sendClickEvent('prensa')" class="ic-pointer !text-black">
    <p class="text-base !mb-0 p-3">
      {{ 'HEADER.PROFILE.prensa' | translate }}
    </p>
  </a>

  @if (isLogged) {
    <hr class="ic-line m-0">
    <div class="p-3 ic-pointer" (click)="logout()">
      <p class="text-base ic-pointer !mb-0">
        {{ 'HEADER.PROFILE.NOTIFICATIONS.close' | translate }}
      </p>
    </div>
  }
</div>