import { ChangeDetectorRef, Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
import { Subject, Subscription, firstValueFrom, takeUntil } from 'rxjs';
import { FirebaseService, UtilsService } from '@app/core/services';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { jamWhatsapp, jamFacebook, jamInstagram, jamYoutube, jamLinkedin } from '@ng-icons/jam-icons';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [MatIconModule, SharedModule, NgIconComponent],
  providers: [provideIcons({ jamWhatsapp, jamFacebook, jamInstagram, jamYoutube, jamLinkedin })],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit, OnDestroy {

  socialNetwork: any[] = [];
  contactCaranty: any[] = [];
  adtInfo:any;
  isMobile!: boolean;
  version: string | undefined;
  scheduleArray: string[] = [];
  private destroy$ = new Subject<void>();
  subDocument!: Subscription;

  constructor(private utilsService: UtilsService, 
              private firebaseService: FirebaseService,
              private breakpointObserver: BreakpointObserver,
              private cdf: ChangeDetectorRef) { 
                this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
                  this.isMobile = result.breakpoints[Breakpoints.XSmall];
                  // this.cdf.detectChanges();
                })
              }

  ngOnInit(): void {
    this.getInfo();
    // if(this.utilsService.isBrowser) {
    //   this.isMobile = window.innerWidth <= 575 ? true : false;
    //   this.utilsService.resizeObservable$.pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
    //     if(result){
    //       const screenWidth = result.target['innerWidth'];
    //       this.isMobile = screenWidth <= 575 ? true : false;
    //     }
    //   })
    // }
    this.version = isDevMode() ? '2.0.1-dev' : '2.0.1';
  }

  get validateDevice(){
    return this.isMobile;
  }

  public async getInfo(): Promise<any> {
    this.subDocument = this.firebaseService.getDocumentData('aditional_information', 'oWu85x43C1L5pmXycQFU').subscribe({
      next: (adtInfo: any) => {
        this.subDocument.unsubscribe();
        if (adtInfo && typeof (adtInfo) == "object" && Object.keys(adtInfo).length) {
          this.adtInfo = adtInfo;
          this.scheduleArray = this.utilsService.stringToArray(this.adtInfo.attentionSchedule, false)
          this.socialNetwork.push(
            { icon: 'jamFacebook', url: adtInfo.profileFacebook, title: 'Facebook', label: 'FOOTER.ariaFacebook' },
            { icon: 'jamInstagram', url: adtInfo.profileInstagram, title: 'Instagram', label: 'FOOTER.ariaInstagram' },
            { icon: 'twitter', url: adtInfo.profileTwitter, title: 'Twitter', label: 'FOOTER.ariaTwitter' },
            { icon: 'jamYoutube', url: adtInfo.profileYoutube, title: 'YouTube', label: 'FOOTER.ariaYoutube' },
            { icon: 'jamLinkedin', url: adtInfo.profileLinkedin, title: 'Linkedin', label: 'FOOTER.ariaLinkedin' }
          )

          this.contactCaranty.push(
            { icon: 'phone_in_talk', text: adtInfo.phone, type: 'phone' },
            { icon: 'mail_outline', text: adtInfo.email, type: 'mail' },
            { icon: 'fab fa-whatsapp', text: adtInfo.whatsapp, type: 'whatsapp' },
          )
        }
        this.cdf.detectChanges();
        // console.log('adtInfo-->',this.adtInfo)
        // console.log('socialNetwork-->',this.socialNetwork)
        // console.log('contactCaranty->',this.contactCaranty)
        
      },
      complete: () => {
        this.subDocument.unsubscribe();
      },
      error: (error: any) => {
        this.subDocument.unsubscribe();
      }
    })
  }

  goSocialNetwork(url:string){
    window.open(url, '_blank')
  }

  getCurrentYear(): string {
    const date = new Date();
    return date.getFullYear().toString();
  }

  goContact(type:string){
    const link = document.createElement("a");
    switch (type) {
      case 'phone':
        link.setAttribute("href", `tel:${this.adtInfo?.phone}`);
        link.click();
        break;
      
      case 'whatsapp':
        window.open(`${this.adtInfo.profileWhatsapp}phone=${this.adtInfo.whatsapp.replace('+52', '').replace(/\s/g, '')}`);
        break;
    
      case 'mail':
        link.setAttribute("href",`mailto:${this.adtInfo?.email}`)
        link.click();
      break;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}