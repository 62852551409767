import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

declare let fbq: any;

@Injectable({
    providedIn: 'root'
})
export class ScriptsService {

    scriptGTM: any;

    constructor(@Inject(DOCUMENT) private document: Document) { }

    loadTagManagerScript() {
        if (this.document.getElementById('google-tag-manager')) {
            console.warn('Facebook Pixel script is already included');
            return;
        }
        const script = this.document.createElement('script');
        script.id = 'google-tag-manager';
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-LHD9V6N1QW';
        script.onload = () => {
            script.innerHTML = `
                window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-LHD9V6N1QW');
            `
        };
        this.document.head.appendChild(script);
    }

    loadFacebookPixel() {
        if (this.document.getElementById('facebook-pixel')) {
            console.warn('Facebook Pixel script is already included');
            return;
        }

        const script = this.document.createElement('script');
        script.id = 'facebook-pixel';
        script.async = true;
        script.innerHTML = `
            !(function(f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        `
        this.document.head.appendChild(script);

        script.onload = () => {
            fbq('init', '1114243689642506');
            fbq('track', 'PageView');
        };

        const noscript = this.document.createElement('noscript');
        noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1114243689642506&ev=PageView&noscript=1"/>`;
        this.document.body.appendChild(noscript);
    }

    loadTagManager() {
        if (this.document.getElementById('tag-manager')) {
            console.warn('Tag manager script is already included');
            return;
        }

        const noscript = this.document.createElement('noscript');
        const frame = this.document.createElement('iframe');
        noscript.id = 'tag-manager';
        frame.setAttribute('url', 'https://www.googletagmanager.com/ns.html?id=GTM-NRG3CCF');
        frame.width = '0';
        frame.height = '0';
        frame.style.display = 'none';
        frame.style.visibility = 'hidden';
        noscript.appendChild(frame);
        this.document.body.appendChild(noscript);
    }
}