import { createReducer, on } from '@ngrx/store';
import * as FilterActions from './filters.actions';

export interface FilterState {
    filters: { [key: string]: any };
    filteredData: any;
    error: string | null;
    page: number;
    total: number;
    filteredItems: any;
}

export const initialState: FilterState = {
    filters: {},
    filteredData: null,
    error: null,
    page: 1,
    total: 0,
    filteredItems: null
};

export const filterReducer = createReducer(
  initialState,
  on(FilterActions.updateFilter, (state, { filter }) => ({
    ...state,
    filters: {
      ...state.filters,
      [filter.key]: filter.value  // Actualiza o agrega un filtro
    },
    filteredItems: {
        ...state.filteredItems,
        [filter.key]: {...filter}
    }
  })),
  on(FilterActions.removeFilter, (state, { key }) => {
    const updatedFilters = { ...state.filters };
    const updatedFilteredItems = { ...state.filteredItems };
    delete updatedFilters[key];  // Elimina el filtro por su clave
    delete updatedFilteredItems[key];  // Elimina también de filteredItems
    return {
        ...state,
        filters: updatedFilters,
        filteredItems: updatedFilteredItems
    };
  }),
  on(FilterActions.updatePage, (state, { page }) => ({
    ...state,
    page  // Actualiza el número de página
  })),
  on(FilterActions.loadFilteredDataSuccess, (state, { data, total }) => ({
    ...state,
    filteredData: data,
    error: null,
    total
  })),
  on(FilterActions.loadFilteredDataFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(FilterActions.clearFilters, (state) => ({
    ...state,
    filters: {},
    filteredItems: null,
    total: 0,
    page: 1
  })),
  on(FilterActions.setFilters, (state, { filters }) => ({
    ...state,
    filters: {
      ...state.filters,
    },
    filteredItems: filters
  }))
);
