import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as FilterActions from './filters.actions';
import { Store, select } from '@ngrx/store';
import { selectCurrentFilters, selectCurrentPage, selectListFilters, selectTotalRegisters } from './filters.selectors';
import { environment } from 'src/environments/environment';

@Injectable()
export class FilterEffects {

    api = environment.endPoint;
  constructor(private actions$: Actions, private http: HttpClient, private store: Store) {}

  loadFilteredData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.updateFilter, FilterActions.updatePage, FilterActions.removeFilter, FilterActions.clearFilters), // Escucha los cambios en los filtros o pagina
      withLatestFrom(
        this.store.pipe(select(selectCurrentFilters)),
        this.store.pipe(select(selectCurrentPage)),
        // this.store.pipe(select(selectListFilters))
    ), // Obtiene los filtros actuales
      switchMap(([action, filters, page]) =>
        this.http.get<any[]>(`${this.api}/cars/all/${page}?request=caranty&pageSize=23`, {
          params: filters  // Pasa todos los filtros como parámetros
        }).pipe(
          map((data:any) => {
            const {total_registros} = data.result;
            return FilterActions.loadFilteredDataSuccess({ data: data.result, total: total_registros })
        }),
          catchError((error) =>
            of(FilterActions.loadFilteredDataFailure({ error: error.message }))
          )
        )
      )
    )
  );
}
