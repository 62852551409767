import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MaterialModule } from '../app/core/modules/material.module';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module'
import { SidebarComponent } from './layout/sidebar/sidebar.component'
import { HeaderComponent } from './layout/header/header.component'
import { FooterComponent } from './layout/footer/footer.component'
import { AuthService } from './core/services/auth.service'
import { FirebaseService, MetaService, StoreService, UtilsService } from './core/services';
import { Subject, takeUntil } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material/sidenav';
import { ScriptsService } from './core/services/3rd-rescources.service';
import { jamWhatsapp } from '@ng-icons/jam-icons';
import { provideIcons } from '@ng-icons/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    MaterialModule,
    SharedModule,
    SidebarComponent,
    HeaderComponent,
    FooterComponent
  ],
  providers: [TranslateService, AuthService, provideIcons({ jamWhatsapp })],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'caranty_latest';
  @ViewChild('sideMenu') sidenav!: MatSidenav;
  private destroy$ = new Subject<void>();
  displayWhatsappButton: boolean = true;
  phoneCaranty!: string;

  constructor(private translate: TranslateService,
            private authService: AuthService,
            private utilsService: UtilsService,
            private storeService: StoreService,
            private matIconRegistry: MatIconRegistry,
            private domSanitizer: DomSanitizer,
            private metaService: MetaService,
            private meta: Meta,
            private scriptsService: ScriptsService,
            private firebaseService: FirebaseService) {
    translate.setDefaultLang(environment.language);
  }

  ngOnInit(): void {
    // this.authService.authState.subscribe(res => {console.log(res)})
    // console.log(this.authService.currentUser)
    // this.authService.user.subscribe(res => {console.log(res)})
    this.getParameters();
    this.registerIcons();
    if(this.utilsService.isBrowser){
      const host = window.location.origin;
      this.metaService.updateCanonicalUrl(host);
      if(this.utilsService.getBrowserName() === 'safari'){
        this.meta.updateTag({name: 'viewport', content: 'width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1, user-scalable=no'});
      }
    }
  }

  ngAfterViewInit(): void {
    this.scriptsService.loadTagManagerScript();
    this.scriptsService.loadFacebookPixel();
    this.scriptsService.loadTagManager();
  }

  getParameters() {
    this.firebaseService.getDocumentData('aditional_information', 'oWu85x43C1L5pmXycQFU').pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result && typeof (result) == 'object' && Object.keys(result).length) {
          this.phoneCaranty = result.whatsapp;
        }
      }
    })
  }

  openCloseSide (event:boolean): void {
    this.sidenav.toggle();
  }

  registerIcons(){
    const server = environment.URL_BASE;
    const URL_BASE = (this.utilsService.isServer ? server : '');
    this.matIconRegistry.addSvgIcon('message', this.domSanitizer.bypassSecurityTrustResourceUrl(`${URL_BASE}/assets/icons/message.svg`));
  }

  sendWhatsapp() {
    if (this.utilsService.isBrowser) {
      const url = document.createElement('a');
      url.href = `https://wa.me/${this.phoneCaranty.replace(/\s/g, "")}`;
      url.target = '_blank';
      url.click();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
