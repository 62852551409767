import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

export const routes: Routes = [
    {
        path: '', 
        loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
    },
    {
        path: 'sobre-caranty', 
        loadComponent: () => import('./pages/about/about.component').then(m => m.AboutComponent)
    },
    {
        path: 'terminos-y-condiciones', 
        loadComponent: () => import('./pages/terms-and-conditions/terms-and-conditions.component').then(m => m.TermsAndConditionsComponent)
    },
    {
        path: 'aviso-de-privacidad', 
        loadComponent: () => import('./pages/privacy/privacy.component').then(m => m.PrivacyComponent)
    },
    {
        path: 'preguntas-frecuentes', 
        loadComponent: () => import('./pages/faqs/faqs.component').then(m => m.FaqsComponent)
    },
    {
        path: 'not-found', 
        loadComponent: () => import('./pages/not-found/not-found.component').then(m => m.NotFoundComponent)
    },
    {
        path: 'blog', 
        loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule)
    },
    {
        path: 'showrooms', 
        loadComponent: () => import('./pages/showrooms/showrooms.component').then(m => m.ShowroomsComponent)
    },
    {
        path: 'vende-tu-auto',
        loadChildren: () => import('./pages/sell/sell.module').then(m => m.SellModule),
    },
    {
        path: 'autos-seminuevos', 
        loadChildren: () => import('./core/components/catalog/catalog.module').then(m => m.CatalogModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'publications',
        loadChildren: () => import('./features/publications/publications.module').then(m => m.PublicationsModule)
    },
    {
        path: 'profile',
        loadComponent: () => import('./features/profile/account/account.component').then(m => m.AccountComponent),
        canActivate: [authGuard]
    },
    {
        path: 'messages',
        // loadComponent: () => import('./features/messages/messages.component').then(m => m.MessagesComponent),
        loadChildren: () => import('./features/messages/messages.routing').then(m => m.MESSAGES_ROUTES),
        canActivate: [authGuard]
    },
    {
        path: 'action',
        loadComponent: () => import('./features/auth/update-password/update-password.component').then(m => m.UpdatePasswordComponent)
    },
    {
        path: 'credito-automotriz',
        loadComponent: () => import('./pages/credit/credit.component').then(m => m.CreditComponent)
    },
    {
        path: 'qrcode',
        loadComponent: () => import('./pages/landing-qr/landing-qr.component').then(m => m.LandingQrComponent)
    },
    {
        path: 'Santander',
        loadComponent: () => import('./pages/landing-santander/landing-santander.component').then(m => m.LandingSantanderComponent)
    },
    {
        path: 'prensa',
        loadComponent: () => import('./pages/journals/journals.component').then(m => m.JournalsComponent)
    },
    {
        path: 'refunds',
        loadComponent: () => import('./core/components/refunds/refunds.component').then(m => m.RefundsComponent),
        canActivate: [authGuard]
    },
    { path: '**', redirectTo: 'not-found', pathMatch: 'full' }
];
