<div class="w-full px-8 ic-principal">
  <div class="container mx-auto">
    <div class="py-6 grid col-span-12">
      <div class="sm:col-span-4 lg:col-span-6 text-center w-full sm:w-3/6 flex justify-center">
        <img src="/assets/logotypes/white/logo-caranty.svg" alt="Autos seminuevos - Logo Caranty" class="img-fluid ic-logo" width="auto" height="100%" itemprop="image" loading="lazy">
      </div>
    </div>
    <div class="flex flex-col sm:flex-row items-center gap-8">
      <div class="hidden sm:flex flex-col w-full sm:w-3/5 xl:w-3/6">
        <p class="ic-copy" itemprop="name">{{'FOOTER.legend' | translate}}</p>
        <ng-container *ngTemplateOutlet="socialMedia"></ng-container>
      </div>
      <div class="flex flex-col w-full sm:w-2/5 xl:w-3/6">
        <p [ngClass]="{'text-center': isMobile, 'ml-6': !isMobile}">
          {{'FOOTER.questions' | translate}}
        </p>
        <div class="ic-contact-info mx-auto mt-4">
          <div class="ic-schedule">
            <mat-icon class="mr-10">schedule</mat-icon>
            <p class="mb-2 flex items-center">{{'FOOTER.scheduleTitle' | translate}}</p>
            <div></div>
            <div class="mb-8 sm:mb-0">
              @for (item of scheduleArray; track item; let i = $index) {
                <p [ngClass]="{'mb-3': i < scheduleArray.length - 1}">{{item}}</p>
              }
            </div>
          </div>
          <div class="flex flex-col">
            @for (item of contactCaranty; track item; let i = $index) {
              <div class="flex items-center"
                [ngClass]="{'mb-3': i < contactCaranty.length - 1}">
                @if (item.type !== 'whatsapp') {
                  <mat-icon class="mr-1">{{item.icon}}</mat-icon>
                }&nbsp;
                @if(item.type === 'whatsapp'){
                  <ng-icon name="jamWhatsapp" class="ic-whatsapp"></ng-icon>
                }
                <p (click)="goContact(item.type)" class="!mb-0" [ngClass]="{'flex flex-col': item.type === 'schedule'}">
                  {{item.text}}
                </p>
              </div>
            }
          </div>
        </div>
        <hr class="block sm:hidden">
      </div>
    </div>
    <hr class="hidden sm:block">
    <div class="flex flex-wrap mt-5">
      <div class="flex-bs-col">
        @if (!isMobile) {
          <div class="">
            <ng-container *ngTemplateOutlet="tagsCaranty"></ng-container>
          </div>
        }@else {
          <div>
            <ng-container *ngTemplateOutlet="tagsCaranty"></ng-container>
            <ng-container *ngTemplateOutlet="socialMedia"></ng-container>
          </div>
        }
      </div>
    </div>
    <div class="flex items-center mt-0 sm:mt-6">
      <div class="flex-bs-col">
        <p class="ic-copyright !mb-0 text-center">
          {{ 'FOOTER.copyright' | translate }}
          <span>{{ getCurrentYear() }}</span>
          <span>{{ 'FOOTER.copyright1' | translate }}</span>&nbsp;
          <span>{{version}}</span>
        </p>
      </div>
    </div>
  </div>
</div>
<ng-template #socialMedia>
  <div class="mt-8 ic-social-media">
    @for (item of socialNetwork; track item) {
      <div class="ic-circle-media">
        @if (item.icon !== 'twitter') {
          <a href="{{item.url}}" target="_blank" rel="noopener noreferrer" itemprop="url" [attr.aria-label]="item.label | translate" [attr.title]="item.title">
            <ng-icon name="{{item.icon}}"></ng-icon>
          </a>
        }
        @if (item.icon === 'twitter') {
          <a href="{{item.url}}" target="_blank" rel="noopener noreferrer" itemprop="url" [attr.aria-label]="item.label | translate" [attr.title]="item.title">
            <img src="/assets/img/icons/logo_twitter.png" alt="logo x" class="img-fluid" width="16px" height="16px" />
          </a>
        }
      </div>
    }
  </div>
</ng-template>
<ng-template #tagsCaranty>
  <div class="flex items-center">
    <div class="flex-bs-col">
      <div class="ic-links">
        <a [routerLink]="['/sobre-caranty']" itemprop="url" title="Sobre Caranty">
          {{'FOOTER.tag4' | translate}}
        </a>
        <a [routerLink]="['/terminos-y-condiciones']" itemprop="url" title="Terminos Y Condiciones">
          {{'FOOTER.tag5' | translate}}
        </a>
        <a [routerLink]="['/preguntas-frecuentes']" itemprop="url" title="Preguntas Frecuentes">
          {{'FOOTER.tag6' | translate}}
        </a>
        <a [routerLink]="['/aviso-de-privacidad']" itemprop="url" title="Aviso De Privacidad">
          {{'FOOTER.tag7' | translate}}
        </a>
        <a [routerLink]="['/showrooms']" itemprop="url" title="Showrooms">
          {{'FOOTER.tag8' | translate}}
        </a>
        <a [routerLink]="['/blog']" itemprop="url" title="Blog">
          {{'FOOTER.tag9' | translate}}
        </a>
      </div>
    </div>
  </div>
</ng-template>