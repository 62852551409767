import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { HttpLoaderFactory } from './core/modules/translate';
import { provideFirebaseApp, initializeApp, initializeServerApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { CarantyHttpInterceptor } from '@app/core/interceptors/http.interceptor';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { filterReducer, appReducer, sellReducer, formsReducer } from 'src/app/store/reducers.reducers'
import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { provideEffects } from '@ngrx/effects';
import { FilterEffects } from './store/filters/filters.effects';
import { filterReducer as filterReducerTest } from './store/filters/filters.reducers';
import { AppRouteReuseStrategy } from './core/utils/route-reuse-strategy';

export const appConfig: ApplicationConfig = {
    providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideDatabase(() => getDatabase()),
    provideEnvironmentNgxMask(),
    // provideNgIconsConfig({}),
    provideStore({ filters2: filterReducerTest, app: appReducer, sell: sellReducer, forms: formsReducer }),
    provideEffects([FilterEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    // provideAnimationsAsync(),
    importProvidersFrom(TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    })),
    { provide: HTTP_INTERCEPTORS, useClass: CarantyHttpInterceptor, multi: true },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings },
    { provide: RECAPTCHA_LOADER_OPTIONS, useValue: environment.language },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    provideEffects()
]
};