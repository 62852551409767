import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { SharedModule } from '../../shared/shared.module'
import { MaterialModule } from '../../core/modules/material.module'
import { NgClass, TitleCasePipe, DatePipe, AsyncPipe, NgTemplateOutlet, DecimalPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, filter, from, lastValueFrom, map, startWith } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService, CarsService, FirebaseService, UtilsService } from '@app/core/services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MessagesService } from '@app/core/services';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { jamMessagesF, jamMessagesAltF, jamUser, jamCreditCard, jamKey, jamMessageWriting, jamLogIn } from '@ng-icons/jam-icons';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { updateFilter } from '@app/store/filters/filters.actions';
import { TypesFilter } from '@app/core/interfaces';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgClass,
    TitleCasePipe,
    AsyncPipe,
    DatePipe,
    NgTemplateOutlet,
    DecimalPipe,
    SharedModule,
    MaterialModule,
    NgIconComponent
  ],
  providers: [MessagesService, DatePipe, provideIcons({ jamMessagesF, jamMessagesAltF, jamUser, jamCreditCard, jamKey, jamMessageWriting, jamLogIn })],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() openSide = new EventEmitter();
  @Input() displayButtonLogin = true;
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  @ViewChild('searchCars') searchCars!: ElementRef;
  displayContent = false;
  isMobileDevice = false;
  displayAnimation = false;
  logged = false;
  isDesktop = false;
  currentUserLogged: any = null;
  messNotification = 0;
  countMessages = 0;
  notificationsCount = 0;
  notificationsArr: any = [];
  formSearch: FormGroup = new FormGroup({});
  messagesArr: any = [];
  autoCompleteSearch: Observable<any> | undefined;
  urlRedirect: string = '';
  resultAutocomplete: string[] = [];
  private destroy$ = new Subject<void>();
  idUser!: string;
  carData: any;
  roomId: string = '';
  conversationArr: any[] = [];
  styles: Partial<CSSStyleDeclaration> = {
    position: 'absolute'
  }
  private searchSubject = new Subject<string>();
  sideNav = false;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private loginDialog: MatDialog,
    public authService: AuthService,
    private router: Router,
    private carService: CarsService,
    private messagesService: MessagesService,
    // private profileService: ProfileService,
    private utilsService: UtilsService,
    private firebaseService: FirebaseService,
    private datePipe: DatePipe,
    // private subsService: SubscriptionsService,
    // private storageService: StorageService,
    // private alertService: AlertService,
    private breakpointObserver: BreakpointObserver,
    private store: Store) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.WebLandscape]).subscribe(result => {
      this.isMobileDevice = result.breakpoints[Breakpoints.XSmall] ? true : false;
      // this.isDesktop = result.breakpoints[Breakpoints.WebLandscape] ? true : false;
      this.isDesktop = breakpointObserver.isMatched('(min-width: 600px)');
    })
  }

  ngOnInit(): void {
    this.formSearch = this.fb.group({
      search: ['']
    })

    this.authService.authState.pipe(takeUntil(this.destroy$)).subscribe(async user => {
      if (user) {
        this.idUser = user.uid;
        this.logged = true;
        this.getMessages();
        this.getNotifications(this.idUser);
        const userData = await lastValueFrom(from(this.authService.getUserByEmail(user.email?.toString() ?? '')));
        if (userData) {
          this.currentUserLogged = userData;
        } else {
          this.currentUserLogged = {
            name: '----',
            firstLastName: '----'
          }
        }
      }
    })

    if (this.utilsService.isBrowser) {
      this.stylesLikeAnimation(window.innerWidth);
      this.utilsService.resizeObservable$.pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          const screenWidth = result.target?.innerWidth;
          this.stylesLikeAnimation(screenWidth);
        }
      })
      const search = window.location.search;
      if (search && search.includes('search=')) {
        const criteria = search.slice(search.indexOf('='));
        this.formSearch.get('search')?.setValue(criteria.replace('=', ''));
      }
    }

    // this.subsService.favoriteObj$.subscribe(result => {
    //   if(result) {
    //     this.displayAnimation = true;
    //     setTimeout(() => {
    //       this.displayAnimation = false;
    //     }, 5000);
    //   }
    // }),
    // this.subsService.objSearch$.subscribe(result => {
    //   if(result){
    //     this.formSearch.get('search').setValue('');
    //     this.autoCompleteSearch = null;
    //     this.resultAutocomplete = null;
    //     this.router.navigateByUrl('/autos-seminuevos')
    //   }
    // })

    this.searchSubject.pipe(debounceTime(600)).subscribe((searchValue) => {
      if (searchValue && searchValue.length >= 2) {
        searchValue = searchValue.replace(/[^a-zA-Z0-9 ]/gi, '');
        this.autocompleSearch(searchValue);
      }
    });
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.urlRedirect = event.urlAfterRedirects;
        if (['/auth/signup', '/auth/signin', 'update-profile', '/auth/update-profile'].includes(event.url)) this.displayContent = false;
        else this.displayContent = true;
      }
    })
  }

  onDebounce(target: any) {
    this.searchSubject.next(target?.value)
  }

  stylesLikeAnimation(width: number) {
    if (width <= 575) {
      this.styles.left = '-37px';
      this.styles.top = '-25px';
    }
    if (width >= 1025) {
      this.styles.left = '-35px';
      this.styles.top = '-35px';
    }
    if (width >= 900 && width <= 1024) {
      this.styles.left = '-20px';
      this.styles.top = '-20px';
    }
    if (width >= 576 && width <= 899) {
      this.styles.left = '-27px';
      this.styles.top = '-20px';
    }
  }

  async getMessages(): Promise<any> {
    if (this.carData) {
      this.messagesService.getCurrentMessage(this.roomId).subscribe((res: any) => {
        this.messNotification = 0;
        this.conversationArr = [];
        this.conversationArr = res;
        const refuseMessage = res.filter((item: any) => item.key == 'lastMessage');
        const isRefuse = refuseMessage.length && refuseMessage[0].data.type == "refuse" ? true : false;
        const activeRoom = res && typeof (res) == "object" && res.length > 0 ? res[0] : false;
        if (this.conversationArr.length > 0 && activeRoom && !isRefuse) {
          this.conversationArr.forEach((el: any, index: number) => {
            if (el.lastMessage?.recieved?.uid === this.idUser && !el.lastMessage?.recieved?.value) {
              this.messNotification++;
            }
          });
        }
      });
    }
  }

  getNotifications(id: string) {
    this.notificationsArr = [];
    this.messagesService.getNotificationsWithValueChanges(id).pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result && result?.length) {
        this.notificationsCount = 0;
        this.countMessages = 0;
        this.notificationsArr = [];
        this.messagesArr = [];
        const messagesNotifications = result.filter((e: any) => e.typeMessage == 'message' && e.status == false);
        const notifications = result.filter((e: any) => e.typeMessage == 'notification' && e.status == false);
        this.notificationsCount = notifications.length;
        this.countMessages = messagesNotifications.length;
        this.notificationsArr = notifications;
        this.messagesArr = messagesNotifications;
      }
    })
  }


  autocompleSearch(search: string) {
    this.carService.searchKeyWords(search).subscribe(result => {
      if (result.code == 200 && result.result && result.result.length) {
        let coincidences = [];
        for (const item of result.result) {
          let brand = item.brand.trim();
          let model = item.model.trim();
          brand = this.utilsService.removeRepeatedWords(brand);
          model = this.utilsService.removeRepeatedWords(model);
          coincidences.push(`${brand} ${item.model}`)
        }
        coincidences = [...new Set(coincidences)]
        this.resultAutocomplete = coincidences;

        this.autoCompleteSearch = this.formSearch.get('search')?.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value)),
        );
      }
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.resultAutocomplete.filter(e => e.toLowerCase().includes(filterValue));
  }

  public getInitials(name: string, surnames: string): string {
    const firstLastName = surnames?.split(' ') ?? '';
    const fullName = `${name} ${firstLastName ? firstLastName[0] : ''}`;	
    return fullName
      .split(' ')
      .map(word => word[0] ? word[0].toUpperCase() : '')
      .join('');
  }

  logout(event: Event): void {
    event.preventDefault();
    const url = window.location.pathname;
    this.authService.logOut(url);
  }

  public goMessages(): void {
    this.router.navigate(['/messages']);
    // this.setActiveClass();
  }

  openSideNav() {
    this.sideNav = !this.sideNav;
    this.openSide.emit(this.sideNav)
  }

  async getNotification(data: any) {
    await this.firebaseService.updateRegisterWithSubCollection('users', this.idUser, 'notifications', data.id, { status: true });
    const currentPath = this.router.url;
    if(data.hasOwnProperty('typeMessage') && data.typeMessage === 'message' && currentPath && currentPath != '/messages'){
      this.router.navigateByUrl(data.route);
    }
  }

  searchCar() {
    const searchValue = this.formSearch.get('search')?.value;
    const currentUrl = this.router.url;
    const navigation = () => {
      if (searchValue && currentUrl.includes('autos-seminuevos')) {
        this.router.navigate(['/autos-seminuevos'], { queryParams: { search: searchValue } })
      } else {
        this.router.navigate(['/autos-seminuevos'], { queryParams: { search: searchValue } })
      }
      this.trigger.closePanel();
      if (this.searchCars) this.searchCars.nativeElement.blur();
      if(searchValue){
        const filter = {
          description: `Búsqueda: ${searchValue}`,
          value: searchValue,
          key: TypesFilter.CRITERIA,
          display: true
        }
        this.store.dispatch(updateFilter({ filter }));
      }
    }
    if ([null, undefined, ""].includes(searchValue)) {
      navigation();
      return;
    }
    searchValue && navigation();
  }

  validateSearch(event: any) {
    const value = event.target.value.replace(/[^a-zA-Z0-9 ]/gi, '');
    this.formSearch.get('search')?.setValue(value);
  }

  optionSelected(selected: MatAutocompleteSelectedEvent) {
    const searchValue = selected.option.value.toLowerCase();
    const currentUrl = this.router.url;
    if (searchValue && currentUrl.includes('autos-seminuevos')) {
      this.router.navigate(['/autos-seminuevos'], { queryParams: { search: searchValue } })
    } else {
      this.router.navigate(['/autos-seminuevos'], { queryParams: { search: searchValue } })
    }
  }

  getDate(date: any): string {
    const secondsToMilliseconds = date.seconds * 1000;
    const nanosecondsToMilliseconds = date.nanoseconds / 1000000;
    const timestampInMilliseconds = secondsToMilliseconds + nanosecondsToMilliseconds;
    const parsedDate = new Date(timestampInMilliseconds);
    return this.datePipe.transform(parsedDate, 'dd/MM/yyyy') ?? '';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}