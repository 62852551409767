import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions.actions';
import { IStoreFilters } from '@app/core/interfaces';


// const initialStateFilters: ReadonlyArray<IStoreFilters> = [];
const initialStateFilters = {
    items: [],
};
const initialStateApp: ReadonlyArray<any> = [];
interface SellState {
    car: any; // Adjust the type based on your car details structure
    price: number | null;
    pictures: string[]; // Assuming pictures are stored as an array of URLs or file paths
  }
const initialStateSell: SellState = {
    car: null,
    price: 0,
    pictures: []
};
  
export const filterReducer = createReducer(
    initialStateFilters,
    on(Actions.addFilter, (state:any, action) => {
        const filterExist = state.items.find((x:any) => x.filterId == action.itemFilter.type);
        console.log({filterExist})
        if (filterExist) {
            const updatedItems = state.items.map((item: any) =>
                item.itemId === action.itemFilter.type
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            );
            return {
                ...state,
                items: updatedItems,
            };
        } else {
            return {
                ...state,
                items: [...state.items, action.itemFilter],
            };
        }
    }),
    on(Actions.removeFilter, (state, action) =>
        ({...state, items: state.items.filter((x:any) => x.filterId !== action.id)}
    )),
    on(Actions.searchFilter, (state, action) =>
        ({...state, items: state.items.filter((x:any) => x.type !== action.typeFilter)}
    )),
    on(Actions.removeFilterByType, (state, action) =>
        ({...state, items: state.items.filter((x:any) => x.type !== action.typeFilter)}
    )),
    on(Actions.clearFilters, (state) => ({...state, items: []}))
);

export const appReducer = createReducer(
    initialStateApp,
    on(Actions.addParameters, (state, action) => {
        return {
            ...state,
            parameters: action.parameters
        }
    }),
    on(Actions.getParameters, (state, action) => {
        return {
            ...state,
            parameters: action.parameters
        }
    })
);

export const sellReducer = createReducer(
    initialStateSell,
    on(Actions.addCarValues, (state, action) => ({
        ...state,
        car: action.details
    })),
    on(Actions.addPrice, (state, action) => ({
        ...state,
        car: state.car ? { ...state.car } : null,
        price: action.price
    })),
    on(Actions.getCarValues, (state, action) => ({
        ...state,
        car: action.data.car,
        price: action.data.price,
        pictures: action.data.pictures
    }))
);

////////////////////
interface FormsState {
    form1: { [key: string]: any } | null;
    form2: { [key: string]: any } | null;
    form3: { [key: string]: any } | null;
  }
  
  const initialState: FormsState = {
    form1: null,
    form2: null,
    form3: null,
  };
export const formsReducer = createReducer(
    initialState,
    on(Actions.saveForm1Data, (state, action) => ({
        ...state,
        form1: action.data,
    })),
    on(Actions.saveForm2Data, (state, action) => ({
        ...state,
        form2: action.data,
    })),
    on(Actions.saveForm3Data, (state, action) => ({
        ...state,
        form3: action.data,
    }))
);