<main class="main">
  <mat-sidenav-container>
    <mat-sidenav class="ic-nav" [fixedInViewport]="true" #sideMenu>
      <app-sidebar (clickEvent)="openCloseSide($event)" />
    </mat-sidenav>
    <mat-sidenav-content>
      <app-header (openSide)="openCloseSide($event)" />
      <div class="ic-component-container">
        <router-outlet />
        @if (displayWhatsappButton) {
          <button mat-fab class="ic-share-wa z-20" (click)="sendWhatsapp()" aria-label="whatsapp button">
            <ng-icon name="jamWhatsapp" size="30"></ng-icon>
          </button>
        }
      </div>
      <ng-template #snackBar>
        <p class="ic-size-14 mb-0">
          asas
        </p>
      </ng-template>
      <app-footer />
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>