<mat-toolbar class="mat-nav-header" [ngClass]="{'no-height': !displayContent}">
  <mat-toolbar-row class="p-0 flex !flex-col">
    @if (displayContent) {
      <!-- @if(isMobileDevice){ -->
        <div class="flex sm:hidden items-center w-full mt-3">
          <div class="flex-1">
            <div class="ic-menu flex-fill">
              <mat-icon class="ms-1" (click)="openSideNav()">menu</mat-icon>
              @if (displayAnimation) {
                <img src="/assets/img/like.gif" width="100" height="100" class="ic-like">
              }
            </div>
          </div>
          <button type="button" class="ic-logo-container cursor-pointer flex-initial" [routerLink]="['/']">
            <img src="/assets/logotypes/white/logo-caranty.svg" alt="autos seminuevos - Logo Caranty" class="ic-logo w-full" width="100" height="50" title="logo_caranty" loading="lazy">
          </button>
          @if (!logged) {
            <div class="flex-[1_0_5%]">
              <ng-container *ngTemplateOutlet="login"></ng-container>
            </div>
          } @else {
            <div class="flex-bs-col">
              <ng-container *ngTemplateOutlet="notificationsMessages"></ng-container>
            </div>
          }
        </div>
      <!-- } -->
      <div class="mx-0 w-full mt-3 grid grid-cols-12 gap-4 items-center">
        <!-- @if (isDesktop) { -->
          <div class="text-center hidden sm:flex items-center col-span-2 sm:col-span-3 xl:col-span-2">
            <div class="ic-menu">
              <mat-icon class="ms-1" (click)="openSideNav()">menu</mat-icon>
              @if(displayAnimation){
                <img src="/assets/img/like.gif" width="100" height="100" class="ic-like">
              }
            </div>
            <div class="ic-logo-container me-4 cursor-pointer">
              <img src="/assets/logotypes/white/logo-caranty.svg" alt="autos seminuevos - Logo Caranty" class="ic-logo w-auto cursor-pointer" [ngClass]="logged && isDesktop ? 'ms-5' : 'ms-3'" width="100%" height="100%" title="logo_caranty" loading="lazy" [routerLink]="['/']">
            </div>
          </div>
        <!-- } -->
        <div class="col-span-12 md:col-span-5 xl:col-span-6">
          <ng-container *ngTemplateOutlet="search"></ng-container>
        </div>
        @if(logged && !isMobileDevice){
          <div class="col-span-1 md:col-span-2 xl:col-span-1 px-1">
            <ng-container *ngTemplateOutlet="notificationsMessages"></ng-container>
          </div>
        }
        @if(!logged && isDesktop){
          <div class="col-span-4">
            <ng-container *ngTemplateOutlet="login"></ng-container>
          </div>
        }
        @if(logged){
          <div class="hidden sm:block col-span-2 xl:col-span-3">
            <div class="flex items-center w-full">
              <!-- @if (isDesktop) { -->
                <div class="w-3/6 hidden lg:block">
                  <div class="ic-user-badget">
                    <p class="text-white !m-0 text-base flex items-center justify-start">
                      <span class="hidden 2xl:block">Mi saldo:</span>&nbsp;
                      <span class="text-sm 2xl:text-base" title="{{ currentUserLogged?.balance ?? 0 | number:'1.0-0':'en-US'}}">${{ currentUserLogged?.balance ?? 0 | number:'1.0-0':'en-US'}}.00</span>
                    </p>
                  </div>
                </div>
              <!-- } -->
              <div class="flex w-full lg:w-3/6 justify-center">
                <div class="relative inline-flex items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-caranty-primary" [matMenuTriggerFor]="menu">
                  <span class="font-medium text-white text-base">{{ getInitials(currentUserLogged?.name, currentUserLogged?.firstLastName) }}</span>
                </div>
                <!-- @if (isDesktop) { -->
                  <p class="hidden lg:block ic-profile-name ms-2 !mb-0 !ml-2" [matMenuTriggerFor]="menu">
                    {{ currentUserLogged?.name | titlecase }}
                  </p>
                <!-- } -->
                <mat-icon class="text-white mt-1" [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon>

                <mat-menu class="ic-mat-menu-profile animated fadeIn" #menu="matMenu">
                  <div class="ic-notifications">
                    <a href="#" (click)="$event.preventDefault()" class="flex items-center p-3 -mt-2 text-sm text-gray-600 transition-colors duration-300 transform hover:bg-gray-100">
                       <div class="flex-shrink-0 object-cover mx-1 rounded-full w-9 h-9 bg-gray-700 flex items-center justify-center">
                         <mat-icon class="text-white">person_outline</mat-icon>
                       </div>
                      <div class="mx-1">
                          <h1 class="!text-sm font-semibold text-gray-700 !mb-0">{{ 'HEADER.PROFILE.hi' | translate }}</h1>
                          <p class="!text-base text-gray-500 font-semibold">{{ currentUserLogged?.name | uppercase }}</p>
                      </div>
                    </a>
                    <hr class="border-gray-200">
                    <a [routerLink]="['/profile']" class="flex items-center p-3 text-sm text-caranty-gray-200 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                      <ng-icon name="jamUser" class="mx-1 text-caranty-gray-200" size="20"></ng-icon>
                      <span class="mx-1">
                        Mi perfil
                      </span>
                    </a>
                    <a [routerLink]="['/refunds']" class="flex items-center p-3 text-sm text-caranty-gray-200 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                      <ng-icon name="jamCreditCard" class="mx-1 text-caranty-gray-200" size="20"></ng-icon>
                      <span class="mx-1">
                        {{ 'HEADER.PROFILE.refund' | translate }}
                      </span>
                    </a>
                    <a [routerLink]="['/publications']" class="flex items-center p-3 text-sm text-caranty-gray-200 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                      <ng-icon name="jamKey" class="mx-1 text-caranty-gray-200" size="20"></ng-icon>
                      <span class="mx-1">
                        {{ 'HEADER.PROFILE.my-cars' | translate }}
                      </span>
                    </a>
                    <a [routerLink]="['/messages']" class="flex items-center p-3 text-sm text-caranty-gray-200 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                      <ng-icon name="jamMessageWriting" class="mx-1 text-caranty-gray-200" size="20"></ng-icon>
                      <span class="mx-1">
                        {{ 'HEADER.PROFILE.messages' | translate }}
                      </span>
                    </a>
                    <hr class="border-gray-200">
                    <a href="#" (click)="logout($event)" class="flex items-center p-3 text-sm text-caranty-gray-200 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                      <ng-icon name="jamLogIn" class="mx-1 text-caranty-gray-200" size="20"></ng-icon>
                      <span class="mx-1">
                        {{ 'HEADER.PROFILE.NOTIFICATIONS.close' | translate }}
                      </span>
                    </a>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>  
        }
      </div>
    }
  </mat-toolbar-row>
</mat-toolbar>

<ng-template let-width="width" #login>
  <button type="button" class="btn ic-btn-login px-6" 
    [style.width]="width"
    [ngClass]="!logged ? 'float-end':''"
    [routerLink]="['/auth/signin']">
    {{ 'HEADER.PROFILE.login'| translate }}
  </button>
</ng-template>

<ng-template #notificationsMessages>
  <div class="flex items-center ic-notifications-messages">
    <div class="w-3/6">
      @if (logged && countMessages > 0) {
        <mat-icon svgIcon="message" [matMenuTriggerFor]="messagesNot" class="cursor-pointer ic-icon-message text-white" [matBadge]="countMessages" matBadgeColor="warn" aria-hidden="false"></mat-icon>
      } @else {
        <mat-icon svgIcon="message" class="cursor-pointer ic-icon-message text-white" (click)="goMessages()"></mat-icon>
      }
      <mat-menu class="ic-mat-menu-messages animated fadeIn" #messagesNot="matMenu">
        <div class="flex">
          <p class="ic-notf-text ic-size-14 ic-color-greyish-brown mb-0 cursor-pointer" [routerLink]="['/messages']">
            {{ 'HEADER.PROFILE.NOTIFICATIONS.text8' | translate }}
          </p>
        </div>
        <hr class="ic-line my-0">
        <div class="ic-container">
          @for (message of messagesArr; track message.date) {
            <div class="ic-mess cursor-pointer">
              <div class="flex items-center p-2" (click)="getNotification(message)">
                <p class="ic-size-12 ic-color-greyish-brown mb-0 ic-text-message tracking-normal">{{ message.message }}</p>
                <div class="ic-dates-container">
                  <span class="text-caranty-gray-200 text-xs">{{ getDate(message.date) }}</span>
                </div>
              </div>
            </div>
          }
        </div>
      </mat-menu>
    </div>
    <div class="w-3/6 relative">
      @if (logged && notificationsCount > 0) {
        <mat-icon [matMenuTriggerFor]="notifications" class="cursor-pointer ic-notification text-white" [matBadge]="notificationsCount" matBadgeColor="warn">notifications</mat-icon>
      } @else {
        <mat-icon class="cursor-pointer ic-notification overflow-visible text-white" [matMenuTriggerFor]="notifications">notifications</mat-icon>
      }
      <mat-menu class="ic-mat-menu-messages animated fadeIn" #notifications="matMenu">
        <p class="ic-notf-text ic-size-14 ic-color-greyish-brown mb-0">
          {{ 'HEADER.PROFILE.NOTIFICATIONS.text1' | translate }}
        </p>
        <hr class="ic-line my-0">
        @if (notificationsArr && notificationsArr?.length > 0) {
          <div class="ic-container">
            @for (message of notificationsArr; track message) {
              <div class="ic-mess cursor-pointer">
                <div class="flex items-center p-3" (click)="getNotification(message)">
                  <div class="ic-icon-container">
                    <mat-icon class="ic-color-solid-verde">info</mat-icon>
                  </div>
                  <p class="ic-size-12 ic-color-greyish-brown mb-0 ic-text-message tracking-normal">{{ message.message }}</p>
                  <div class="ic-dates-container">
                    <span class="text-caranty-gray-200 text-xs">{{ getDate(message.date) }}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="ic-no-messages-container">
            <p class="ic-size-14">{{ 'HEADER.notNotifications' | translate }}</p>
          </div>
        }
      </mat-menu>
    </div>
  </div>
</ng-template>

<ng-template #search>
  <form class="form-inline" [formGroup]="formSearch" action="">
    <div class="ic-container-search flex items-center relative">
      <input type="search" id="search" name=search
        class="form-control ic-input-search w-full p-1 placeholder:!text-base"
        placeholder="{{'HEADER.placeholderSearch' | translate}}"
        formControlName="search"
        [matAutocomplete]="auto"
        (keyup.enter)="searchCar()"
        (keydown.enter)="searchCar()"
        (keypress)="validateSearch($event)"
        (input)="onDebounce($event.target)" #searchCars>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
          @for (item of autoCompleteSearch | async; track item) {
            <mat-option [value]="item">
              {{item | titlecase}}
            </mat-option>
          }
        </mat-autocomplete>
        <button type="button" class="input-group-text text-white w-auto p-4 flex items-center justify-center" (click)="searchCar()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </form>
  </ng-template>