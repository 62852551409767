import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { switchMap, catchError, filter, take, retry } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
// import { AlertService } from '../services/alert.service';


@Injectable({
  providedIn: 'root'
})

export class CarantyHttpInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService,
    // private alertService: AlertService,
    // private utilsService: UtilsService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = '', reqClone: any;
    if (isPlatformBrowser(this.platformId)) token = localStorage.getItem("token") as string;
    if (req.url.includes('request=caranty')) {
      if (token) {
        const isTokenExpired = this.authService.tokenExpired(token);
        let isUserLogged;
        if (isPlatformBrowser(this.platformId)) isUserLogged = localStorage.getItem("isLogged");
        // Se valida si es un usuario en sesion
        if (isUserLogged && isUserLogged == "1") {
          this.validateSession();
        }

        if (isTokenExpired) { //Si el token ya caduco se manda el request default
          reqClone = req.clone({
            setHeaders: {
              [environment.apiHeader]: environment.apiKey
            }
          });
          return this.handle403Error(req, next);
        } else { // Si el token es valido se agregan los headers
          reqClone = req.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              [environment.apiHeader]: environment.apiKey
            }
          })
        }
      } else {
        reqClone = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            [environment.apiHeader]: environment.apiKey
          }
        })
      }
    } else if (req.url.includes('type=azure')) {
      reqClone = req.clone({
        url: req.url + '&backend=azure',
        setHeaders: {
          'x-functions-key': environment.azureApiKey,
          'Content-type': 'application/json',
          [environment.apiHeader]: environment.apiKey
        }
      });
    } else if (req.url.includes('type=twilio')) {
      reqClone = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hcmlvQG1hcmlvLmNvbSIsInBhc3N3b3JkIjoiMTIzNDUiLCJpYXQiOjE2MDY0Mjk0NDN9.pSi_QJBH1Be8s_jWg_IUZogT8wAEvWUGNODDYLsRcD4',
          [environment.apiHeader]: environment.apiKey
        }
      })
    } else if (req.url.includes('securetoken.googleapis')) {
      reqClone = req.clone();
    } else if (req.url.includes('santander/verano2022')) {
      reqClone = req.clone();
    } else if (req.url.includes('type=default')) {
      reqClone = req.clone();
    }
    else {
      reqClone = req.clone({
        setHeaders: {
          [environment.apiHeader]: environment.apiKey
        }
      })
    }

    //Se agrega el operador retryWhen para reintentar la petición 2 veces cada 700ms cuando no se obtenga un status.code=200
    return next.handle(reqClone).pipe(
      retry(1),
      catchError(error => {
        // Si el token ya caduco se hace un refreshToken
        if (error instanceof HttpErrorResponse && error.status === 403) {
          return this.handle403Error(req, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  /**
   * Método para obtener un nuevo token
   * @param request
   * @param next
   * @author GADC 23/02/2022
   */
  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.id_token);
          return next.handle(this.injectToken(request));
        }), catchError((e) => {
          this.isRefreshing = false;
          return throwError(() => e);
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          return next.handle(this.injectToken(request));
        }));
    }
  }

  /**
   * Método para mandar los headers con el nuevo token
   * @param request
   * @summary GADC 23/02/2022
   */
  injectToken(request: HttpRequest<any>) {
    const token = localStorage.getItem("token");
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        [environment.apiHeader]: environment.apiKey
      }
    });
  }

  /**
   * Método que valida si existe el refreshToken en la sesion
   */
  validateSession() {
    if (isPlatformBrowser(this.platformId)) {
      const containRefreshToken = localStorage.getItem("refreshToken");
      if ([null, undefined, ""].includes(containRefreshToken)) {
        // this.alertService.info('Hemos actualizado algunos datos de tu sesión, para una mejor experiencia inicia sesión nuevamente gracias').then(result => {
        //   if (result.isConfirmed) {
        //     this.authService.logOut('/');
        //     // this.utilsService.openLoginDialog();
        //     this.refreshTokenSubject.next(null)
        //   }
        // })
      }
    }
  }
}